<script>
  import appConfig from "@/app.config";
  import storage from "@/services/storage.js";
  import selectRoute from "@/views/dashboard/messages/global/selectRoute.vue";
  export default {
    page: {
      title: "default account settings",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
      selectRoute,
    },
    data() {
      return {
        storage: new storage(),
        selectedRoute: "",
        loginOptions: [
          {
            value: "sms",
            label: "Bulk SMS",
          },
          {
            value: "voice_messages",
            label: "Voice Messages",
          },
          {
            value: "always",
            label: "Always Ask",
          },
        ],
        selectedLogin: "sms",
      };
    },
    methods: {
      setDefaultLogin() {
        this.storage.saveAndDelete("defaultLoginType", this.selectedLogin);
      },
    },
    computed: {
      getLoginType() {
        return this.loginOptions.find(
          (option) => option.value === this.selectedLogin
        ).label;
      },
      formattedRoute() {
        return this.selectedRoute.replace("_", " ");
      },
    },
    mounted() {
      // Analytics
      this.$mixpanel.track("Change Default Type Page");
      // for franco
       if (this.$store.getters["auth/currentUser"].country === "CI") {
          this.$mixpanelFranco.track("Change Default Type Page");
        }
    },
  };
</script>
<style scoped>
  .active {
    background: #f7921c !important;
    color: #fff !important;
  }

  #account-settings {
    height: calc(100vh - 120px);
  }

  .default-header {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #484a4f;
  }

  .default-description {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    color: #979797;
  }

  .btn {
    outline: none;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #484a4f;
  }

  .btn:active {
    outline: none;
    border: none;
  }

  .dropdown-item {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #484a4f;
    background: transparent;
  }

  .current-space {
    text-transform: capitalize;
    color: #f7921c;
  }
</style>
<template>
  <div class="col-md-12">
    <div id="account-settings" class="mnotify-card">
      <div id="side-indicators-content" class="mnotify-card-header">
        <div class="profile-settings-section-header">
          <profilesettingsBtn />
          <div class="profile-settings-sub-header">
            <h3 class="mnotify-card-header-text pb-2 my-0">
             
              {{ $t("dashboard.schedule_voice.title") }} 
            </h3>
            <p class="profile-description">
              
              {{ $t("dashboard.account_settings.account_defaults.sub_header") }}
            </p>
          </div>
        </div>
      </div>
      <div class="mnotify-card-body">
        <div class="row justify-content-between" style="margin-top: 2em">
          <div class="col-md-6">
            <h3 class="default-header">
              {{ $t("dashboard.account_settings.account_defaults.default") }} 
              <span class="current-space">{{ getLoginType }}</span>
            </h3>
            <p class="default-description">
              {{ $t("dashboard.account_settings.account_defaults.sub_text") }} </p>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <el-select
              size="small"
              @change="setDefaultLogin"
              v-model="selectedLogin"
             :placeholder="$t('dashboard.account_settings.account_defaults.form.placeholder')"

            >
              <el-option
                v-for="(login, index) in loginOptions"
                :key="index"
                :label="login.label"
                :value="login.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <!-- <div class="row justify-content-between" style="margin-top: 2em">
          <div class="col-md-6">
            <h3 class="default-header">
              Default SMS Route:
              <span class="current-space">{{ formattedRoute }}</span>
            </h3>
            <p class="default-description">Choose the default route for SMS</p>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
              <selectRoute
                :inputSize="'small'"
                v-model.lazy="selectedRoute"
                showHelp
                :fullWidth="false"
              />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
